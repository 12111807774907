import React from 'react';
import { motion } from 'framer-motion';
import { FaGraduationCap, FaChalkboardTeacher, FaBookOpen } from 'react-icons/fa';
import { MdSchool, MdComputer,MdMoneyOff  } from 'react-icons/md';
import { GiBookshelf, GiTeacher, GiRetroController, GiTvRemote, GiTv  } from 'react-icons/gi';
import './SectionContent.css';

// Variants for different animation directions
const leftToRight = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
};

const rightToLeft = {
  hidden: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1 } },
};

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } },
};

const iconStyle = { fontSize: '4em', marginBottom: '10px' };

// Section 1: 2 columns
export function Section1() {
  return (
    <div className='secao-apresentacao' style={{ display: 'flex', width: '100%' }}>
      <motion.div
        style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        variants={leftToRight}
      >
        <h1>Aprenda. <span className='color-blue-mark'>Evolua.</span> Conquiste.</h1>
        <p className='presentation-text'>
        <b>Edu<span className='color-blue-mark'>Hub</span></b> é sua plataforma de cursos online que oferece acesso gratuito a conteúdos de qualidade e opções avançadas para quem deseja ir além.
            <ul>
            <li><b>Aprenda:</b> Novas habilidades com cursos gratuitos.</li><br />
            <li><b><span className='color-blue-mark'>Evolua:</span></b> Com conteúdos criados por especialistas.</li><br />
            <li><b>Conquiste:</b> certificações e reconhecimento profissional.</li><br />
        </ul>
            Bem-vindo ao <b>Edu<span className='color-blue-mark'>Hub</span></b>, onde cada curso é uma nova oportunidade. Transforme seu conhecimento e alcance seus objetivos conosco.
        </p>
        
      </motion.div>
      <motion.div
        style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        variants={rightToLeft}
      >
        <img src="./images/eduhub-vert.png" alt="Promo" style={{ maxWidth: '100%' }} />
        <button className='get-started-button'>Começe Agora</button>
      </motion.div>
    </div>
  );
}

export function cSection2() {
    const items = [
      { icon: <FaGraduationCap style={iconStyle} />, text: 'Curso Online' },
      { icon: <MdSchool style={iconStyle} />, text: 'Educação' },
      { icon: <FaChalkboardTeacher style={iconStyle} />, text: 'Aulas ao Vivo' },
      { icon: <GiTeacher style={iconStyle} />, text: 'Instrutores' },
    //   { icon: <FaBookOpen style={iconStyle} />, text: 'Livros' },
    //   { icon: <GiBookshelf style={iconStyle} />, text: 'Biblioteca' },
      { icon: <MdComputer style={iconStyle} />, text: 'E-learning' },
      { icon: <FaGraduationCap style={iconStyle} />, text: 'Certificação' },
    ];
  
    return (      
      <div className='secao-categoria'>      
        <h1>Aprenda. <span className='color-blue-mark'>Evolua.</span> Conquiste.</h1>
        <div style={{display: 'flex',    flexDirection: 'row',    flexWrap: 'wrap'}}>            
            {items.map((item, index) => (
            <motion.div
                key={index}
                style={{ flex: '1 0 50%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                variants={index % 2 === 0 ? leftToRight : rightToLeft}
            >
                <div className="card-item">
                    {item.icon}
                    <p>{item.text}</p>
                </div>
            </motion.div>
            ))}
        </div>  
      </div>
    );
  }


  export function Section2() {
    const items = [
      { icon: <MdMoneyOff style={iconStyle} />, text: 'Cursos Gratuitos', details: 'Acesse uma ampla quantidade de cursos sem nenhum custo, de diversas áreas do conhecimento.' },
      { icon: <GiTeacher style={iconStyle} />, text: 'Tutores Especializados', details: 'Aprenda com especialistas altamente qualificados e experientes em suas respectivas áreas, garantindo uma educação de qualidade.' },
      { icon: <FaBookOpen style={iconStyle} />, text: 'Material de Apoio', details: 'Aproveite uma variedade de materiais de apoio, incluindo ebooks, artigos e exercícios, para complementar seu aprendizado.' },
      { icon: <GiTv  style={iconStyle} />, text: 'Conteúdo On-Demand', details: 'Tenha acesso ao conteúdo a qualquer hora e em qualquer lugar, permitindo que você estude no seu próprio ritmo.' },
      { icon: <GiRetroController style={iconStyle} />, text: 'Gamefication', details: 'Transforme seu aprendizado em uma experiência divertida e envolvente com elementos de gamificação que incentivam o progresso.' },
      { icon: <FaGraduationCap style={iconStyle} />, text: 'Suporte ao Aluno', details: 'Nossa equipe de suporte ao aluno está aqui para ajudar você a superar qualquer desafio durante seus estudos.' },
    ];
  
    return (
      <div className="secao-categoria">
        <h1>O que <span className='color-blue-mark'>oferecemos.</span></h1>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {items.map((item, index) => (
            <motion.div
              key={index}
              style={{ flex: '1 0 50%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
              variants={index % 2 === 0 ? leftToRight : rightToLeft}
              className='card-area color-blue-mark'
            >
              <div className={`card-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                {item.icon}
                <p>{item.text}</p>
                <div className="details">
                  {item.details}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    );
  }
// Section 3: 3 columns
export function cSection3() {
  const items = Array.from({ length: 3 }, (_, i) => (
    <motion.div
      key={i}
      style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      variants={fadeIn}
    >
      <h2>Título {i + 1}</h2>
      <p>Descrição {i + 1}</p>
      <button>Ação {i + 1}</button>
    </motion.div>
  ));
  return <div style={{ display: 'flex', width: '100%' }}>{items}</div>;
}
export function Section3() {
    const items = [
      {
        title: "Tutores: A Base do Conhecimento",
        description: "Apoiando e valorizando nossos especialistas. Nossos tutores trazem conhecimento, experiência e paixão pelo ensino.",
        action: "Saiba Mais"
      },
      {
        title: "Alunos: O Coração da Nossa Plataforma",
        description: "Transformando vidas através do aprendizado. Oferecemos uma experiência de aprendizado acessível, enriquecedora e transformadora.",
        action: "Comece Agora"
      },
      {
        title: "Responsabilidade da Empresa",
        description: "Construindo um futuro educacional sustentável e inclusivo. Comprometidos com a excelência, a ética e a inclusão.",
        action: "Nossa Missão"
      }
    ];
  
    return (
      <div className="section3">
        {items.map((item, index) => (
          <motion.div
            key={index}
            className="pillar"
            initial="hidden"
            animate="visible"
            variants={fadeIn}
          >
            <h2>{item.title}</h2>
            <p>{item.description}</p>
            <button>{item.action}</button>
          </motion.div>
        ))}
      </div>
    );
  }

// Section 4: 2 columns 2 rows
export function Section4() {
  const items = Array.from({ length: 4 }, (_, i) => (
    <motion.div
      key={i}
      style={{ flex: '1 0 50%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
      variants={i % 2 === 0 ? leftToRight : rightToLeft}
    >
      <p>Texto {i + 1}</p>
    </motion.div>
  ));
  return <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>{items}</div>;
}

// Section 5: 2 rows
export function Section5() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
      <motion.div
        style={{ width: '65%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        variants={fadeIn}
      >
      <img src="./images/eduhub-vert.jpg" alt="Promo" style={{ maxWidth: '50%' }} />

      </motion.div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <motion.p variants={leftToRight}>Texto</motion.p>
        <motion.button variants={rightToLeft}>Ação</motion.button>
      </div>
    </div>
  );
}
