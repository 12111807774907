import React from 'react';
import Modal from 'react-modal';
import './PrivacyPolicyModal.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
};

Modal.setAppElement('#root');

function PrivacyPolicyModal({ isOpen, onRequestClose, onAccept }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Privacy Policy"
    >
      <h2>Política de Privacidade</h2>
      <button onClick={onRequestClose} className="close-button">&times;</button>
      <div className="modal-content">
        <p>Aqui você deve incluir o conteúdo da sua política de privacidade...</p>
        {/* Adicione o conteúdo real da política de privacidade */}
      </div>
      <button onClick={onAccept} className="accept-button">Aceitar e Fechar</button>
    </Modal>
  );
}

export default PrivacyPolicyModal;
