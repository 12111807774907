import React from 'react';

function Footer() {
  return (
    <footer>
      <p>&copy; EduHub 2024 - Deus seja louvado!</p>
    </footer>
  );
}

export default Footer;
