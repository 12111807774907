import React, { useState, useEffect } from 'react';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import './CookieConsent.css';

function CookieConsent() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cookieConsent, setCookieConsent] = useState(localStorage.getItem('cookieConsent') || false);

  useEffect(() => {
    if (cookieConsent) {
      localStorage.setItem('cookieConsent', 'true');
    }
  }, [cookieConsent]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleAccept = () => {
    setCookieConsent(true);
    closeModal();
  };

  if (cookieConsent) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <p>
        Usamos cookies para melhorar sua experiência no nosso site. 
        <button onClick={openModal} className="link-button">Saiba mais</button>
      </p>
      <button onClick={handleAccept} className="accept-button">Aceitar</button>
      <PrivacyPolicyModal isOpen={modalIsOpen} onRequestClose={closeModal} onAccept={handleAccept} />
    </div>
  );
}

export default CookieConsent;
