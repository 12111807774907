import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const sectionStyle = {
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 50pt',
};

function Section({ id, children, customVariants, bgColor }) {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });
  sectionStyle.background = '#'+bgColor;

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      id={id}
      ref={ref}
      style={sectionStyle}
      initial="hidden"
      animate={controls}
      variants={customVariants}
    >
      {children}
    </motion.div>
  );
}

export default Section;
