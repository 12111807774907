import React from 'react';
import Section from '../components/Section';
import { Section1, Section2, Section3, Section4, Section5 } from '../components/SectionContent';


const colorOffWhite = "eeeeee";
const colorMiddleBlue = "002e43";
const colorGray = "cccccc";
const colorMidGray = "666666";

function Home() {
  return (
    <div>
      <Section id="section1" bgColor={colorMidGray}><Section1 /></Section>
      <Section id="section2" bgColor={colorOffWhite}><Section2 /></Section>
      <Section id="section3" bgColor={colorOffWhite}><Section3 /></Section>
      {/* <Section id="section4" bgColor={"bbffff"}><Section4 /></Section>
      <Section id="section5" bgColor={colorOffWhite}><Section5 /></Section> */}
    </div>
  );
}

export default Home;
