import React from 'react';
import { Link } from 'react-scroll';
import { FaDoorOpen } from 'react-icons/fa';


function Header() {
  return (
    <header>
      <img src="./images/logo-eduhub.png" alt="Promo" style={{ maxWidth: '100%' }} />
      <nav>
        <ul>
          <li><Link to="section1" smooth={true} duration={1000}>Home</Link></li>
          <li><Link to="section2" smooth={true} duration={1000}>Categorias</Link></li>
          <li><Link to="section3" smooth={true} duration={1000}>Parceiros</Link></li>
          <li><Link to="section4" smooth={true} duration={1000}>Missão</Link></li>
          <li><Link to="section5" smooth={true} duration={1000}>Roadmap</Link></li>
        </ul>
      </nav>
      <button className='login-button'><span>Entrar</span> <FaDoorOpen /> </button>
    </header>
  );
}

export default Header;
